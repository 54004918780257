@import "../../App.css";

.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--container-dark-background);
    z-index: 1000; /* High z-index to ensure it's above other content */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--nav-spacing);
}

.logo {
    width: 90px;
    margin-right: var(--nav-spacing);
}

.logo:hover {
    cursor: pointer;
}

.navbar-buttons {
    display: flex;
    align-items: center;
}

.navbar-button {
    color: var(--lightgray);
    font-family: var(--font);
    font-size: 19px;
    font-weight: var(--semi-bold);
    padding: 10px var(--nav-spacing);
}

.navbar-button:hover {
    color: white;
    cursor: pointer;
    background-color: #d126b5;
    border-radius: 5px;
}

/* Responsive adjustments if necessary */
@media (max-width: 768px) {
    .navbar-buttons {
        display: none; /* Hide buttons on smaller screens */
    }
    /* Other responsive styles as needed */
}
