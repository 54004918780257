.tag-cloud {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 20px;
  }
  
  .tag {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 20px;
    background-color: #f0f0f0; /* Default background color */
    transition: background-color 0.3s, font-size 0.3s;
    cursor: pointer;
  }
  
  .tag:hover {
    background-color: lightcoral; /* Background color on hover */
    color: #ffffff; /* Text color on hover */

    
  }
  
  