@import "../../App.css";

.education-experience-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
}

.education {
    flex-basis: 40vw;
    margin: 5vw;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education-info .description-text {
    margin-bottom: 10px;
}

.ut-logo {
    width: 25vw;
    height: auto;

    margin-bottom: 40px;
}

.experience-container {
    flex-basis: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.experience-element {
    box-sizing: border-box;
    width: 100%;
    margin: 1vw 0;
    padding: 1vw;
    display: flex;
    align-items: center;
    text-align: left;
}

.experience-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.experience-logo {
    width: 5vw;
    height: auto;
    margin-right: 2vw;
}
.subheading-text{
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 0.5vw;
}
.heading-text{
    /* make the text centered */
    text-align: center;
    font-size: 3vw;
    font-weight: bold;
    margin-top: 1%;
    margin-bottom: 0.5vw;
}