/* Blog.css */
.blog-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color and background */
}

  
.back-button:hover {
    background-color: lightpink; /* Change to your preferred background color on hover */
    color: black; /* Change to your preferred text color on hover */
}
  
.blog-header {
    text-align: center;
    margin-bottom: 40px;
}

.blog-header h1 {
    margin: 0;
    font-size: 2.5em; /* Adjust size as needed */
}

.blog-subtext {
    font-size: 1em; /* Adjust size as needed */
    color: #666; /* Adjust color as needed */
    margin-top: 10px;
}

.blog-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid #ddd; /* Adjust border as needed */
    padding: 20px;
    width: 80%; /* Adjust width as needed */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    transition: background-color 0.3s; /* Smooth transition for background color change */

}

.blog-text {
    flex: 1;
}

.blog-image-container {
    /* Adjust the width as necessary, or use max-width for responsiveness */
    width: 20%; 
    overflow: hidden; /* Ensures the image does not overflow the container */
}
  
.blog-image {
    width: 80%; /* Makes the image responsive */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the area, may clip sides */
    /* You can also use object-fit: contain; if you don't want to clip the image */
}
  /* Blog.css */
.blog-card-link {
    color: black; /* Change to your preferred text color */
    text-decoration: none; /* Removes underline from links */
}
.blog-card:hover {
  background-color: lightgrey; /* Change to the desired hover background color */
}

