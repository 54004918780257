/* BlogPost.css */
.blog-post-page {
    max-width: 800px;
    margin: auto;
    padding: 2rem;
}
  
.blog-post-header {
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}
  
.blog-post-title {
    font-size: 2rem;
    margin: 0;
}
  
.blog-post-date {
    color: #666;
    margin: 0;
}
  
.blog-post-content {
    white-space: pre-wrap; /* Preserves spaces and line breaks */
    word-break: break-word; /* Breaks long words */
    /* increase text size */
    font-size: 1.3rem;
    line-height: 1.5;
    margin-bottom: 2rem;

}
/* Styles specific to the image within the blog post content */
.blog-post-content .blog-post-image {
    max-width: 100%; /* Limit the width within the content area */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Display block to apply width and height properly */
    margin: 0 auto; /* Center the image */
  }
  