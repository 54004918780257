
:root {
  --lightgray: #36454F;
  --font: 'Inter', sans-serif;
  --blue: #9B72AA;
  --lightblue: lightblue;
  --regular: 400;
  --medium: 500;
  --semi-bold: 00;
  --bold: 700;
  --nav-spacing: 30px;
  --main-dark-background: #FAF4FF;
  --secondary-dark-background: #c59494;
  --container-dark-background: #cad4fa;
}
html {
  background-color: var(--main-dark-background);
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
.intro-text {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 16px;
  color: var(--lightgray);
}
.heading-text {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 75px;
  color: var(--lightgray);
}
/* About me */
.subheading-text {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 50px;
  color: var(--lightgray);
}
/* Experience/internship title */
.small-subheading-text {
  font-family: var(--font);
  font-weight: var(--bold);
  font-size: 24px;
  color: var(--lightgray);
}
/* experience/inter ship subtext */
.small-description-text {
  font-family: var(--font);
  font-weight: var(--medium);
  font-size: 25px;
  line-height: 40px;
  color: var(--lightgray);;
}

.smaller-description-text {
  font-family: var(--font);
  font-weight: var(--medium);
  font-size: 12px;
  line-height: 40px;
  color: var(--lightgray);
}
/* banner and about me and educations description */
.description-text {
  font-family: var(--font);
  font-weight: var(--medium);
  font-size: 28px;
  line-height: 35px;
  color: var(--lightgray);
}
.general-container {
  background-color: var(--container-dark-background);
  border-radius: 20px;
  border: solid var(--lightgray) 1px;
}
.construction {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mobile-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}
.wrench {
  height: 200px;
}